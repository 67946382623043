<template>
  <div class="flex flex-wrap align-items-center justify-content-center p-fluid">
    <div class="col-12 md:col-5">
      <Toast />
      <div class="card">
        <div class="grid formgrid">
          <div class="col-12">
            <h4>查詢外送門市</h4>
          </div>
          <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
            <span class="p-input-icon-left p-float-label">
              <i class="pi pi-map-marker" />
              <InputText type="text" id="address" v-model="addressValue"
                v-bind:class="{ 'p-invalid': isInvalidAddress }" />
              <label for="address">外送地址</label>
            </span>
          </div>
          <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
            <Button v-on:click="fetchAvailableShops" label="尋找門市" class="p-button-secondary p-button mr-2" />
          </div>

          <!-- 選擇門市 -->
          <!--<p class="line-height-3 m-0">
                        <ul v-if="filteredShops.length != 0" class="p-1 m-0">
                            <Span v-for="shop in filteredShops" :key="shop.branch">
                                <Button style="width:100%;" class="mt-2 p-button-purpply p-button-outlined">
                                    {{ shop.name }} {{ shop.branch }}
                                    <span v-if="shop.busy" class="p-text-purpply ml-3 text-pink-400"> [忙碌中] </span>
                                    <span v-if="shop.min" class="p-text-purpply ml-3"> 低消 ${{shop.min}}</span>
                                </Button>
                            </Span>
                        </ul>
                        <ul v-else class="p-1 m-0">
                            很抱歉，此地址附近查無門市，請嘗試其他地址或自取，謝謝您。
                        </ul>
                    </p>-->
          <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
            <ul v-if="filteredShops.length != 0" class="p-0 m-0">
              <Span v-for="shop in filteredShops" :key="shop.branch">
                <Button style="width:100%;" class="mt-2 p-button-purpply p-button-outlined">
                  {{ shop.name }} {{ shop.branch }}
                  <span class="p-text-purpply ml-3">{{ shop.phone_number }}</span>
                  <span v-if="shop.busy" class="p-text-purpply ml-3 text-pink-400"> [忙碌中] </span>
                  <span v-if="shop.min" class="p-text-purpply ml-3"> 低消 ${{ shop.min }} </span>
                </Button>
              </Span>
            </ul>
            <ul v-else class="p-1 m-0">
              請先輸入外送地址，再點選查詢門市，謝謝您
            </ul>
          </div>

          <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
            <Button v-on:click="redirectLogin" label="點此返回" class="p-button-outlined p-button-secondary p-button mr-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      addressValue: "",
      shops: [],
      availableShops: [],
    };
  },
  mounted() {
    this.fetchInfo();
  },
  beforeUnmount() { },
  created() { },
  methods: {
    fetchAvailableShops() {
      if (this.addressValue.trim() === "") {
        alert("請先輸入外送地址，再點選查詢門市，謝謝您");
        return;
      }
      // clear shop
      this.shop_hash = null;
      this.shop_name = null;
      this.shop_branch = null;

      if (this.addressValue == null) return;

      var params = {
        addr: this.addressValue,
        b: localStorage.brandName,
      };
      console.log("fetchAvailableShops: " + params["addr"]);
      axios(process.env.VUE_APP_ONLINE_ORDERING_SITE_API_BASE_URL + "/fs", {
        method: "GET",
        params: params,
      })
        .then((response) => {
          if (response.data["code"] == 200) {
            console.log(response.data);
            this.availableShops = response.data["shop"];
            if (response.data["shop"].length == 0) this.$toast.add({ severity: 'info', summary: '目前此地址附近查無門市，謝謝您', life: 3000 });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    fetchInfo() {
      const shop_name = localStorage.brandName;

      var params = {
        shop_name: shop_name,
      };

      axios(process.env.VUE_APP_ONLINE_ORDERING_SITE_API_BASE_URL + "/search_shops", {
        method: "GET",
        params: params,
      })
        .then((response) => {
          // {code: 200, intent: "redirect", page: "status", hash: "3wnndx2YgFiIZDfQeSBlEe3FTWn"}
          console.log(response.data);
          this.shops = response.data["shops"];
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    redirectLogin() {
      this.$router.push({
        path: "/login", // Entrance
        name: "login",
      });
    }
  },
  computed: {
    filteredShops() {
      var shop_list = JSON.parse(JSON.stringify(this.shops)); // clone by json trick
      var available_shops = [];
      var i;
      var mapping = {};

      for (i = 0; i < this.availableShops.length; i++) {

        available_shops.push(this.availableShops[i].h);
        mapping[this.availableShops[i].h] = this.availableShops[i];
      }

      for (i = shop_list.length - 1; i >= 0; i--) {
        if (!available_shops.includes(shop_list[i].h) ||
          mapping[shop_list[i].h]['min'] == -1) shop_list.splice(i, 1); // remove if not available
        else {
          shop_list[i]['min'] = mapping[shop_list[i].h]['min']; // 低消
          shop_list[i]['busy'] = mapping[shop_list[i].h]['busy']; // 忙碌
        }
      }

      console.log(shop_list);
      return shop_list;
    },
  },
};
</script>

<style scoped lang="scss">
.p-text-purpply {
  color: var(--gray-700);
}

.btn {
  border-radius: 10px;
  border-bottom: 0px;
  display: inline-block;
  padding: 10px;
  position: relative;
  text-align: center;
  transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
  display: none;

  &+label {
    cursor: pointer;
    min-width: 120px;
    background-color: var(--purpply-unselected-color);

    &:hover {
      background: none;
      color: var(--purpply-primary-color);
    }

    &:after {
      background-color: var(--purpply-primary-color);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
      width: 100%;
      z-index: -1;
    }
  }

  &.toggle-left+label {
    border-right: 0;

    &:after {
      left: 100%;
    }
  }

  &.toggle-right+label {
    margin-left: 0px;

    &:after {
      left: -100%;
    }
  }

  &:checked+label {
    cursor: default;
    color: #fff;
    background-color: var(--purpply-primary-color);
    transition: color 200ms;

    &:after {
      left: 0;
    }
  }
}
</style>
